import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest, HttpHandler, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry, tap, finalize } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
// import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';

import { Login, SnackBarMessage } from "./model"
import { MatSnackBar } from '@angular/material/snack-bar';
import * as FileSaver from 'file-saver';



@Injectable({
  providedIn: 'root'
})
export class HttpService {
  subAdminUserList(obj: { limit: number; offset: number; status: any; search: any; }) {
    throw new Error('Method not implemented.');
  }
  editSubAdminUser(obj: { ids: any[]; deletedAt: boolean; }) {
    throw new Error('Method not implemented.');
  }
  getPermissions() {
    throw new Error('Method not implemented.');
  }
  deleteRole(obj: { role_id: any; }) {
    throw new Error('Method not implemented.');
  }
  addRole(object: { name: string; }) {
    throw new Error('Method not implemented.');
  }

  SERVER_URL = environment.SERVER_URL;
  SCHOOL_REGISTRATION = environment.SCHOOL_REGISTRATION;
  sideBarHeight: number = 0;
  page
  profile
  isLoading: any;
  constructor(private loader: NgxUiLoaderService, private route: ActivatedRoute, private _snackBar: MatSnackBar,
    private router: Router, private http: HttpClient, private toastrService: NbToastrService) {

    // alert(env.production)
  }

  getToken() {

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {
      // alert("token in local")

      return localStorage.getItem("token")

    } if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {

      //  alert("token in session")

      return sessionStorage.getItem("token")

    } else {

      return null

    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    this.loader.start();
    let ok: string;
    console.log("request111111", String(request.url).includes('admin/get/list-of-pre-registration'))
    if (String(request.url).includes('admin/get/list-of-pre-registration') === false &&
      String(request.url).includes('admin/get/details/list-of-pre-registration') === false) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.getToken()}`
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => ok = event instanceof HttpResponse ? 'succeeded' : '',
        // Operation failed; error is an HttpErrorResponse
        error => ok = error
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        this.loader.stop();
        const elapsed = Date.now() - started;
        if (ok == "succeeded") {
          const msg = `${request.method} "${request.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
          console.log(msg);
        } else {
          console.log(ok);

          if (ok["status"] == 401) {
            // logout from here
            localStorage.clear()
            sessionStorage.clear()
            this.router.navigate(['auth/login']);
            console.log(ok["status"]);
          } else {
            this.handleError(ok);
          }
        }

      })
    );
  }

  isAuthenticated() {

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return localStorage.getItem('isLogged') == "true"

    } if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {

      return localStorage.getItem('isLogged') == "true"

    } else {

      return false

    }
  }


  objToQueryString(obj: any): string {
    const searchParams = new URLSearchParams();
    const params = obj;
    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
  }


  loggedUserDetails() {


    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return JSON.parse(localStorage.getItem("login_form"))

    } if (localStorage.getItem("remember_me")) {

      return JSON.parse(sessionStorage.getItem("login_form"))

    } else {

      return null

    }

  }

  setLoggedUserDetails(object) {

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      localStorage.setItem("admin_details", JSON.stringify(object))

    } if (localStorage.getItem("remember_me")) {


      sessionStorage.setItem("admin_details", JSON.stringify(object))

    } else {

    }

  }

  showSuccessMessage(object: SnackBarMessage) {

    // this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
    //   duration: 3000,
    //   horizontalPosition: "center",
    //   verticalPosition: "bottom",
    // });

    const config = {
      status: "success",
      duration: 3000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
    const titleContent = object.message;
    this.toastrService.show(
      ``,
      `${titleContent}`,
      config);


  }

  showErrorMessage(object: SnackBarMessage) {
    this._snackBar.open(
      object.message,
      object.action ? object.action : 'CLOSE',
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snackbarError'],
      }
    );
  }

  updateLoginDetails(details) { // whenever talent/talent edit name and name will reflect

    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return localStorage.setItem("admin_details", JSON.stringify(details))

    } if (localStorage.getItem("remember_me")) {

      return sessionStorage.setItem("admin_details", JSON.stringify(details))

    }


  }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error && error.error.errors) {
      // client-side error and server side
      if (Array.isArray(error.error.errors.msg)) {
        // validation error message

        if (error.error.errors.msg.length) {
          const ob = error.error.errors.msg[0];
          if (ob.msg == 'IS_EMPTY') {
            errorMessage = `${ob.param} missing`;
          } else {
            errorMessage = ob.msg;
          }
        }
      } else {
        errorMessage = error.error.errors.msg;
      }
    } else {
      // server-side error
      if (error.status == 401) {
        // Unauthorised
        // this.commonService.logout();
        this.router.navigate(['/']);
      }

      console.log(error);
      errorMessage = `Something went wrong here`;
      // errorMessage = `${error.message}`;
    }
    console.log('I am here ', errorMessage);
    // if (this.bulkUpload(error)) {
    //   return;
    // }
    try {
      this.showErrorMessage({
        message: errorMessage,
      });
    } catch (err) {
      alert(errorMessage);
    }

    return throwError(errorMessage);
  }

  //login
  login_data(data: Login): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/adminLogin`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  getUsersList(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getUserList`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  forgot_pswd(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/forgotPassword`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    );
  }

  resetPassword(data: any, id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/resetPassword/${id}`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  changePassword(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/changePassword`
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  mestaSchoolsStudentsList(form: any): Observable<any> {
    const query = this.objToQueryString(form);
    const API_URL = `${this.SCHOOL_REGISTRATION}admin/get/list-of-pre-registration?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  ///
  mestaSchoolsStudentsDetail(form: any): Observable<any> {
    const query = this.objToQueryString(form);
    const API_URL = `${this.SCHOOL_REGISTRATION}admin/get/details/list-of-pre-registration?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //------------------Subject management---------------



  //-----------Subject Listing
  subject_listing(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getSubject`
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //------------------Add subject
  saveSubject(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createSubject`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //------------------Delete Subject
  deleteSubject(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deleteSubject?id=${id}`;
    return this.http.delete(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //--------Update Subject---------------
  updateSubject(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateSubject`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //-------get single data
  getSingleSubject(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getSubjectById?subject_id=${id}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //----------------get School listing
  // school_listing(): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/getSchool`
  //   return this.http.get(API_URL)
  //     .pipe(
  //       map(res => {
  //         return res;
  //       }),
  //     );
  // }
  //-------------Add school-----
  saveSchool(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createSchool`
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //------------------Delete School---
  deleteSchoolbyID(id: any): Observable<any> {

    const API_URL = `${this.SERVER_URL}admin/deleteSchool?school_id=${id}`;
    return this.http.delete(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //--------Update School---------------
  updateSchool(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateSchool`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //-----get Single school Data----
  getSingleSchool(id: any): Observable<any> {
    const query = this.objToQueryString(id)

    const API_URL = `${this.SERVER_URL}admin/get/schoolById?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //--------Pagination and school Listing-----
  schoolPagination(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/getSchool?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }
  //-------update School Status
  updateSchoolStatus(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateSchoolStatus`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  //---Pagination and Subject Listing--------
  subjectPagination(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getSubject?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  //-------Pagination and students Listing--------
  // studentPaginationList(data:any):Observable<any>
  // {
  //   const query=this.objToQueryString(data);
  //   const API_URL=`${this.SERVER_URL}admin/getSubject?${query}`;
  //   return this.http.get(API_URL)
  //   .pipe(map(res=>
  //     {
  //       return res
  //     }),
  //     );
  // }

  //-------Students Listing-------
  getStudentList(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getStudentList?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }

    //-------Students Listing-------
    getStudentListAll(data: any): Observable<any> {
      const query = this.objToQueryString(data);
      const API_URL = `${this.SERVER_URL}admin/getStudentListAll?${query}`;
      return this.http.get(API_URL)
        .pipe(
          map(res => {
            return res;
          }),
        );
  
    }

  //-------Students Listing-------
  getStudentPaymentStatus(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getStudentListPending?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }
  //-----Registration mgmt. >>  Student Registration Status
  getRegisteredStudentList(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getTempUserList?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }
  //----------Studen Password
  getStudentPassword(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/studentPassList?${query}`;
    return this.http.get(API_URL, id)
      .pipe(
        map(res => {
          return res;
        }
        ),
      );
  }
  //------------Registration mgmt. >>  Student Registration delete
  deleteRegisteredstudents(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deleteTempUser/${id}`;
    return this.http.delete(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //------------Registration mgmt. >>  Student Registration GET by ID
  getRegisteredStudentByID(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/getTempUserById?${query}`;
    return this.http.get(API_URL, id)
      .pipe(
        map(res => {
          return res;
        }
        ),
      );
  }


  //-----Registration mgmt. >>  School Registration Status
  getRegisteredSchoolList(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getTempSchoolList?${query}`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }
  //-------Registration mgmt. >>  School Registration delete
  deleteRegisteredSchool(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deleteTempSchool/${id}`;
    return this.http.delete(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //------------Registration mgmt. >>  Student Registration GET by ID
  getRegisteredSchoolByID(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/getTempSchoolById?${query}`;
    return this.http.get(API_URL, id)
      .pipe(
        map(res => {
          return res;
        }
        ),
      );
  }



  //--------Add students---------
  addStudent(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/add/students`;
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  //------get Student by ID------
  getStudentDetails(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/getStudentDetails?${query}`;
    return this.http.get(API_URL, id)
      .pipe(
        map(res => {
          return res;
        }
        ),
      );
  }
  updateStudentData(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateStudent`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  //languages
  getLanguages(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getLanguage`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //---------Delete Students--------

  deletestudents(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deletestudent/${id}`;
    return this.http.delete(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //-------------update Student------
  updateStudentStauts(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateStudentStatus`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res
        }),
      );
  }

  createStudent(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createStudent`;
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  //payment 

  payment(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/payment/payumoney`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  successPayment(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/payment/success`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // studentBulkUpload(form?: any): Observable<any> {
  //     const API_URL = `${this.SERVER_URL}admin/uploadedStudentList`;
  //     return this.http.post(API_URL, form).pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  //   }

  studentBulkUpload(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/uploadedStudentList`;
    return this.http.post(API_URL, data, {
      reportProgress: true,
      observe: 'events',
    }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //-----contact Us
  // contactUs(data: any): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/createContactUs`;
  //   return this.http.post(API_URL, data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  schoolBulkUpload(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/uploadSchoolBulk`;
    return this.http.post(API_URL, data, {
      reportProgress: true,
      observe: 'events',
    }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //---------Result Bulk Upload
  resultBulkUpload(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/uploadResultList`;
    return this.http.post(API_URL, data, {
      reportProgress: true,
      observe: 'events',
    }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //---------Result Bulk List
  resultBulkListing(data:any){
    const query=this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/getResultList?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //-------delete result  
  resultDelete(id:any){
    const API_URL = `${this.SERVER_URL}admin/deleteResult?resultId=${id}`;
    return this.http.delete(API_URL).pipe(
      map((res)=>{
        return res;
      })
      );
  }


  //-----contact Us----Add
  contactUs(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createContactUs`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //---------About us
  // aboutUs(data: any): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/createAboutUs`;
  //   return this.http.post(API_URL, data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  //------contact Us-----get
  getContactUs(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getContactUs`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }




  //---------About us-----Add
  aboutUs(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createAboutUs`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //-----About us-------get
  getAboutUs(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getAboutUs`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //------upload Admin images
  uploadAdminImages(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/imageUpload`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //--Upload Admin PDF
  // uploadAdminPDF(data: any): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/pdfUpload`;
  //   return this.http.post(API_URL, data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }


  uploadAdminPDF(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/pdfUpload`;
    return this.http.post(API_URL, data, {
      reportProgress: true,
      observe: 'events',
    }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //------Add Terms&Conditions and privacy policy
  save_Terms_Privacy(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateCms`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }


  //--get Terms&Conditions and privacy policy
  get_Terms_Privacy(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getCms/${data}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  postal_api(data: string): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getcitystateby/pincode?pincode=${data}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //-------Add admit Card
  add_AdmitCard(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/addAdmitCard`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //-------get admit Card
  get_AdmitCard(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getAdmitCardList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //----delete admit card
  delete_admitCard(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deleteAdmitCard?admit_card_id=${id}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  generateAdmitCard(data:any){
    const API_URL = `${this.SERVER_URL}admin/createAdmitCard`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  //-----get generated Admit Card
  getGeneratedAdmitCard(data:any){
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getAdmitCardList?${query}`;
    return this.http.get(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  //----admit Card Status
  update_Status(data: any) {
    const API_URL = `${this.SERVER_URL}admin/updateAdmitCardStatus`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }
  //------get admit by id
  get_AdmitById(id: any) {
    const API_URL = `${this.SERVER_URL}admin/getAdmitCardById?admit_card_id=${id}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );

  }

  //----update admit card
  update_AdmitCard(data: any) {
    const API_URL = `${this.SERVER_URL}admin/updateAdmitCard`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  getAdminProfile(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/adminProfile`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getStudentByPayment(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/getPaymentData?${query}`;
    return this.http.get(API_URL, id)
      .pipe(
        map(res => {
          return res;
        }
        ),
      );
  }

  //enquiry managment

  createEnquiryManagement(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createEnquiryManagement`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //-------get admit Card
  getEnquiryManagementList(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getEnquiryManagementList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  getEnquiryManagementById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getEnquiryManagementById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //----delete admit card
  deleteEnquiryManagementById(id: any): Observable<any> {
    const query = this.objToQueryString(id);

    const API_URL = `${this.SERVER_URL}admin/deleteEnquiryManagementById?${query}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  // //----admit Card Status
  // updateEnquiryManagementById(data: any): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/updateEnquiryManagementById`;
  //   return this.http.patch(API_URL, data).pipe(
  //     map(res => {
  //       return res;
  //     })
  //     );
  //   }

  //-------get Enquiry Management
  getStudyMaterialList(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getEnquiryManagementList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //---- update Enquiry Management By Id
  updateEnquiryManagementById(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateEnquiryManagementById`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  updateEnquiryManagementStatus(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateStatusEnquiryManagement`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  //--------Listing study material
  listingStudyMaterial(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/getListOfStudyMaterials?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );

  }
  //-get study material by ID
  getStudyMaterialByID(id: any) {    
    const API_URL = `${this.SERVER_URL}admin/getListOfStudyMaterialsById?study_id=${id}`;
    return this.http.get(API_URL, id).pipe(
      map(res => {
        return res;
      })
    );
  }
  //-------Mock test get by id   
   getMockTestByID(data: any) {    
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/getListOfStudyMaterialsById?${query}`;
    return this.http.get(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }




  // add study material

  createStudyMeterial(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createStudyMeterial`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

 


  //-------get admit Card
  // getStudyMaterialList(data: any): Observable<any> {
  //   const query = this.objToQueryString(data);
  //   const API_URL = `${this.SERVER_URL}admin/getEnquiryManagementList?${query}`;
  //   return this.http.get(API_URL).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }

  // getListOfStudyMaterialsById(data: any): Observable<any> {
  //   const query = this.objToQueryString(data);
  //   const API_URL = `${this.SERVER_URL}admin/getListOfStudyMaterialsById?${query}`;
  //   return this.http.get(API_URL).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }
  //----delete admit card
  // deleteStudyMaterials(id: any): Observable<any> {
  //   const query = this.objToQueryString(id);

  //   const API_URL = `${this.SERVER_URL}admin/deleteStudyMaterials?${query}`;
  //   return this.http.delete(API_URL).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }
  //----admit Card Status
  // updateStudyMaterials(data: any): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/updateStudyMaterials`;
  //   return this.http.patch(API_URL, data).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );

  // }


  //-----------footer management ------Add

  footerManagementCreate(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createFooterManagement`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res
      })
    )
  }

  //-----------footer management ------Get

  footerManagementGet(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getfootermanagement`
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );

  }

  //-----------Know About MTSE ------AboutExam

  cretaeAboutExam(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createAboutExam`;

    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res
      })
    )
  }
  //-----------Know About MTSE ------Get More

  // getAboutExam(): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}admin/getAboutExamList`
  //   return this.http.get(API_URL).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }
  //-----------Know About MTSE ------Get More

  getAboutExam(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getAboutExamList?${query}`
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //-----------Know About MTSE ------Change Status
  updateExamStatus(data: any): Observable<any> {

    const API_URL = `${this.SERVER_URL}admin/updateAboutExamStatus`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  //----delete Study Materials

  deleteStudyMaterials(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deleteStudyMaterials?study_id=${id}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //------delete Mock Test
  deleteMockTestData(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/deleteStudyMaterials?${query}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //banner section

  getBannerListByid(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getBannerById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }


  createBanner(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createBanners`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  // getBannerList(data:any): Observable<any> {}
  getBannerList(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getBannerList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //----get study material by id
  getListOfStudyMaterialsById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getBannerList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  // getBannerById(data:any): Observable<any> {
  //   const query=this.objToQueryString(data);
  //   const API_URL=`${this.SERVER_URL}admin/getEnquiryManagementById?${query}`;
  //   return this.http.get(API_URL).pipe(
  //     map(res=>{
  //       return res;
  //     })
  //   );
  // }

  deleteBannerById(id: any): Observable<any> {
    const query = this.objToQueryString(id);

    const API_URL = `${this.SERVER_URL}admin/deleteBannerById?${query}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  // deleteBannerById(id:any):Observable<any>{
  //   const query=this.objToQueryString(id);

  //   const API_URL=`${this.SERVER_URL}admin/deleteBannerById?${query}`;
  //   return this.http.delete(API_URL).pipe(

  //----update Study Materials
  updateStudyMaterials(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateStudyMaterials`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  // updateBannerById(data:any):Observable<any>{
  //   const API_URL=`${this.SERVER_URL}admin/updateBanner`;

  updateBannerById(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateBanner`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }


  //------status study Material
  onStatusChange(data: any) {
    const API_URL = `${this.SERVER_URL}admin/updateStatusStudy`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  updateBannerStatus(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateBannerStatus`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }


  createHeroSection(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createHeroSection`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getHeroExamList(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getHeroExamList`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );

  }

  getAboutExamById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getAboutExamById?${query}`
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createQuerySection(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createQuery`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );

  }

  updateExam(data: any) {
    const API_URL = `${this.SERVER_URL}admin/updateAboutExam`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  deleteExam(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/deleteAboutExamById?${query}`;
    return this.http.delete(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  getQueryList(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getqueryList`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }



  //latset and testinomials

  createLatestAndTestinomials(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/homePageManagement`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getLatestAndTestinomials(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getHomePageList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  getHomepageById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getHomepageById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  deleteHomepageById(id: any): Observable<any> {
    const query = this.objToQueryString(id);

    const API_URL = `${this.SERVER_URL}admin/deleteHomepageById?${query}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateHomepageStatus(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateHomepage`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  // student dashboard

  createAnnouncementInformation(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createAnnouncementInformation`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getAnnouncementInformation(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getcreateAnnouncementInformation?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  getAnnouncementInformationById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getAnnouncementInformationById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  deleteAnnouncementInformationById(id: any): Observable<any> {
    const query = this.objToQueryString(id);

    const API_URL = `${this.SERVER_URL}admin/deleteAnnouncementInformationById?${query}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateAnnouncementInformation(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateAnnouncementInformation`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }


  getcontactQueriesList(data: any): Observable<any> {

    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/contactQueriesList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //--------Mock Test
  addMockTest(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/add/mock-test`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //------------getMock Test
  getMockTest(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/get/mock-test/list?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //----get Mock test By ID
  getMocktestByID(id: any): Observable<any> {
    //const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/getMockTestById?id=${id}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //-------update Mock test
  updatedMockTest(id: any) {
    const API_URL = `${this.SERVER_URL}admin/edit/mock-test`;
    return this.http.put(API_URL, id).pipe(
      map(res => {
        return res;
      })
    );
  }



  //------
  deleteMockTest(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/delete/mock-test`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }



  //---------share mock test
  shareMocktest(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/share/mock-test/link`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  //----update Mock Test Status
  statusUpdate(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateStatusMockTest`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //------------
  deletecontactQueriesById(id: any): Observable<any> {
    // const query = this.objToQueryString(id);
    // console.log(query)
    const API_URL = `${this.SERVER_URL}admin/delete/contact-queries/${id}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateContactQueries(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateStatusContactQueries`;

    return this.http.patch(API_URL, data).pipe(
      map(res => {
        console.log("data", res)
        return res;
      })
    );

  }


  // result certificate dection

  getResult_CertificateList(data: any): Observable<any> {

    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getCertificateList?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateResult_Certificate(data: any): Observable<any> {

    const API_URL = `${this.SERVER_URL}admin/updateCertificate`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }
  updateResultStatus(data: any): Observable<any> {

    const API_URL = `${this.SERVER_URL}admin/updateResult`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }

  //----delete Study Materials

  deleteResult_Certificate(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/deleteCertificateById/${id}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //-------Add result/sertificate
  addResultCertificate(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createCertificate`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  //------get By ID Result

  getResultByID(id: any): Observable<any> {
    //const query=this.objToQueryString(id)
    const API_URL = `${this.SERVER_URL}admin/getCertificateById/${id}`;
    return this.http.get(API_URL, id).pipe(
      map(res => {
        return res;
      })
    );
  }


  //-------update result

  updateResult(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateCertificate`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }



  //send reply

  quariesReplly(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/reply/contact-queries`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  getRepllyById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getContactQueriesById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //////////FAQ
  createFaq(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createFaq`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  createHeading(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createFaqTitle`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  getFaq(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getFaq?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  getFaqById(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getFaqById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  deleteFaqById(id: any): Observable<any> {
    const query = this.objToQueryString(id);

    const API_URL = `${this.SERVER_URL}admin/deleteFaqById?${query}`;
    return this.http.delete(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateFaq(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updateFaq`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  updateIndex(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/update/index`;
    return this.http.patch(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }


  //-----------Payment History
  getPaymentHistory(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/paymentHistoryLog?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    )
  }

  //--get Payment History by ID
  getPaymentHistoryByID(id: any): Observable<any> {
    const query = this.objToQueryString(id)
    const API_URL = `${this.SERVER_URL}admin/paymentHistoryLogById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    )
  }
  //--------payment history Invoice download
  paymentHistoryInvoice(obj: any): Observable<any> {
    //const query=this.objToQueryString(obj)
    const API_URL = `${this.SERVER_URL}admin/genrateInvoiceByAdmin`;
    return this.http.post(API_URL, obj).pipe(
      map(res => {
        return res;
      })
    );
  }

  paymentHistoryOfflineInvoice(obj: any): Observable<any> {
    //const query=this.objToQueryString(obj)
    const API_URL = `${this.SERVER_URL}admin/genrateOfflineInvoiceByAdmin`;
    return this.http.post(API_URL, obj).pipe(
      map(res => {
        return res;
      })
    );
  }


  createLanguage(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/createLanguage`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //------------getMock Test
  getLanguage(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getLanguage?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }
  //----get Mock test By ID
  getLanguageByID(id: any): Observable<any> {
    const query = this.objToQueryString(id);
    const API_URL = `${this.SERVER_URL}admin/getLanguageById?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );
  }

  //-------update Mock test
  updateLanguage(id: any) {
    const API_URL = `${this.SERVER_URL}admin/updateLanguage`;
    return this.http.patch(API_URL, id).pipe(
      map(res => {
        return res;
      })
    );
  }



  //------
  deleteLanguage(data: any): Observable<any> {
    const query = this.objToQueryString(data);

    const API_URL = `${this.SERVER_URL}admin/deleteLanguage?${query}`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );
  }
  //----------send--------Email builder
  sendEmail(data: any): Observable<any> {


    const API_URL = `${this.SERVER_URL}admin/sendEmailStudentAndSchool`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }
  //----------get--------Email builder
  getEmail(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getEmailStudentAndSchool?${query}`;
    return this.http.get(API_URL).pipe(
      map(res => {
        return res;
      })
    );

  }
  //----------delete--------Email builder
  deleteEmail(id: any): Observable<any> {


    const API_URL = `${this.SERVER_URL}admin/deleteEmailStudentAndSchool?id=${id}`;
    return this.http.delete(API_URL, id).pipe(
      map(res => {
        return res;
      })
    );

  }
  getTotalRegisterStudent(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/totalRegisterStudent`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }
  getGrades(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/GradeWiseStudentRegistrations`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }
  getStdSchool(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getStdSchoolExamReg`;
    return this.http.get(API_URL)
      .pipe(
        map(res => {
          return res;
        }),
      );

  }

  getUsergradeWiseChart(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/GradeWiseStudentRegistrations`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getStudentRegisterSchoolInMonth(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/totalRegisteredStudents?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getNumberOfStudentFromSchool(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/get/no-of-student/school-wise?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getStdSchoolExamReg(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getStdSchoolExamReg?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getNotification(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/get/notification?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOthersSchoolStudent(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/get-others/school-student-list?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  updateStudentSchool(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/map-student/others-to-reg-/school`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  createOtherSchool(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/create-other/school`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }




  totalRegisterbutnotlogged(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getNotLoggedInStudentList?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  addDiscountSchool(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/addDiscountSchool`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  addOfflinePayment(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/payment/update/success`;
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res;
      })
    );

  }


  getReceiptByAdmin(data: any): Observable<any> {
    const query = this.objToQueryString(data);
    const API_URL = `${this.SERVER_URL}admin/getReceiptByAdmin?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  generateOrderOfStudentByAdmin(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/generateOrderOfStudentByAdmin`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPaymentHistoryByAdmin(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getPaymentHistoryByAdmin?user_id=${data.user_id}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  generateInvoiceByAdmin(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/generateInvoiceByAdmin`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updatePasswordByAdmin(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/updatePasswordByAdmin`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }


  getNewsLetter(data: any): Observable<any> {
    const query = this.objToQueryString(data);

    const API_URL = `${this.SERVER_URL}admin/news/letter/list?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  registerSubadmin(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/registerSubAdmin`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  subAdminList(obj): Observable<any> {
    const query = this.objToQueryString(obj)
    const API_URL = `${this.SERVER_URL}admin/get/sub-admin?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  subAdminListDetails(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/get/sub-admin/details/${obj.id}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateSubAdmin(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/edit/sub-admin`;
    return this.http.patch(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  deleteSubAdmin(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/deleteSubAdmin?${query}`;
    return this.http.delete(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  addSubAdminRole(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/AddRole`;
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  roleDetails(obj): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/get/sub-admin/details/${obj.id}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAllRoles(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/get/all/roles`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  deleteRoles(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/deleteRole?${query}`;
    return this.http.delete(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }
  addRolePermissions(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/addPermissions`;
    return this.http.post(API_URL, data)
      .pipe(
        map(res => {
          return res;
        }),
      );
  }

  getRolePermission(data:any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/getPermissions?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  //------------SEO Management------------
  getSEO(data:any){ 
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}admin/getSeo?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //---Add SEO  
  addSEO(data:any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/add/seo`;
    return this.http.post(API_URL,data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //-------update SEO
  
  updateSEO(data:any): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/edit/seo`;
    return this.http.patch(API_URL,data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //---delete SEO
  deleteSEO(data:any): Observable<any> {    
    const API_URL = `${this.SERVER_URL}admin/deleteSeo?id=${data.id}`;
    return this.http.delete(API_URL)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }


  //----get pages
  getPages(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/getPages`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPermission(): Observable<any> {
    const API_URL = `${this.SERVER_URL}admin/get/RoleManagement`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  //-------Student certificateList
  getCertificateList(data:any){
    const query=this.objToQueryString(data)
    const API_URL= `${this.SERVER_URL}admin/getCertificatesListAll?${query}`;
    return this.http.get(API_URL).pipe(
      map((res)=>{
        return res;
      })
    )
  }
  //--------generate Certificate
  generateCertificate(data:any){
    const API_URL=`${this.SERVER_URL}admin/createNewCertificate`;
    return this.http.post(API_URL,data).pipe(
      map((res)=>{
        return res;     
      })
    )
  }
  //---------status Certificate
  certificateStatus(data:any){    
    //const query=this.objToQueryString(data)
    const API_URL= `${this.SERVER_URL}admin/updateCertificate`;
    return this.http.patch(API_URL,data).pipe(
      map((res)=>{
        return res;
      })
    )
  }
  
  deleteCertificate(data:any): Observable<any> {    
    const API_URL = `${this.SERVER_URL}admin/deleteCertificate?certificateId=${data}`;
    return this.http.delete(API_URL)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  examURL(data:any){
    const API_URL=`${this.SERVER_URL}admin/exam`;
    return this.http.post(API_URL,data).pipe(
      map((res)=>{
        return res;     
      })
    )
  }

  csvDownload(headers: any, data: any) {
    console.log("data:", data);

    if (!data || !data.length) {
      return;
    }
   
   

    const separator = ',';
    const csvContent = headers.join(separator) + '\n' + data.map((rowData: any) => {
      return headers.map((headkey: any) => {
        const property = headkey.toLowerCase().replace(/\s+/g, '_');
        let value = rowData[property] ? rowData[property] : '';

        // Convert the value to a string before checking for a comma
        value = value ? String(value) : '';

        // Check if the value contains a comma, if so, enclose it in double quotes
        if (value.includes(',')) {
          value = '"' + value + '"';
        }

        return value;
      }).join(separator);
    }).join('\n');

    this.exportFile(csvContent, 'text/csv');
  }

  exportFile(data: any, fileType: string) {
    console.log('csvData:', data);

    const blob = new Blob([data], { type: fileType });
    FileSaver.saveAs(blob, 'Downloaded CSV')
  }
}









