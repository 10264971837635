import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../@core/utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {


  constructor(private service : HttpService,private router : Router){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.service.getToken()){
        alert('Please Login First')
        // this.service.showErrorMessage({
        //   message : "Please Login First"
        // })
        this.router.navigate(['/auth/login']);
        return false;
      }else{
        return true;
      }
    
  }
  
}
